<template>
  <v-container class="access-container">
    <div class="login-logo-container">
      <v-img :src="require('@/assets/svg/logo.svg')" contain class="logo" />
      <h3 class="logo-title">
        Облачное файловое хранилище <br />
        Просмотр файла
      </h3>
    </div>

    <state-container ref="main">
      <v-list dense class="list">
        <v-list-item-group
          color="primary"
          @change="onItemChanged"
          v-model="selectedItem"
        >
          <component
            v-for="(item, index) in list"
            :key="index"
            :item="item"
            :index="index"
            :loading="isLoading"
            :divider="index != 0"
            :is="switchListItem(item.type)"
            @click="onButtonClicked"
          />
        </v-list-item-group>
      </v-list>
    </state-container>

    <custom-snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import "@/assets/styles/access.css";

import ApiService from "@/services/api";
import AuthService from "@/services/authADFS";

import StateContainer from "@/components/StateContainer.vue";
import Snackbar from "@/components/SnackBar.vue";

import AdListItem from "@/components/access/AdListItem.vue";
import AnonListItem from "@/components/access/AnonListItem.vue";
import PinListItem from "@/components/access/PinListItem.vue";

import StateMixins from "@/mixins/state";
import ErrorsMixins from "@/mixins/errors";

export default {
  mixins: [StateMixins, ErrorsMixins],

  computed: {
    isAuthorized() {
      return this.$store.getters.IS_AUTHORIZED;
    },
  },

  data() {
    return {
      list: [],

      selectedItem: undefined,
      isLogout: false,
      isLoading: false,

      showModal: false,
    };
  },

  mounted() {
    this.setLoadState("main");
    this.loadData();
  },

  methods: {
    loadData() {
      let linkId = this.$route.params.linkId;
      ApiService.files
        .getAccesses(linkId)
        .then((response) => {
          if (response.data.length) {
            this.list = response.data;
            this.setBaseState("main");
          } else {
            this.setNoDataState("main");
          }
        })
        .catch((error) => {
          if (error.response.data) this.showError(error.response.data.error);
          else this.showError(error);
          this.setErrorState("main");
        });
    },

    switchListItem(type) {
      if (type == 0) return "ad-list-item";
      else if (type == 1) return "pin-list-item";
      else return "anon-list-item";
    },

    onItemChanged(item) {
      if (item == undefined || this.isLoading || item >= this.list.length)
        return;

      let rule = this.list[item];
      if (rule.type == 1 || (rule.type == 0 && this.isLogout)) return;

      if (rule.type == 0) this.login();
      else this.checkRule(rule);
    },

    onButtonClicked(index, isLogout, pin) {
      if (isLogout) {
        this.isLogout = true;
        AuthService.logout();
      } else {
        let rule = this.list[index];
        this.checkRule(rule, pin);
      }
    },

    login() {
      this.isLoading = true;
      if (this.isAuthorized) {
        this.processAccess();
      } else {
        AuthService.login();
      }
    },

    processAccess() {
      let linkId = this.$route.params.linkId;
      ApiService.files
        .getFile(linkId, {
          responseType: "blob",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0];
          this.route(
            {
              allowDownload: response.headers["allowdownload"],
              format: response.headers["fileformat"],
            },
            response.data,
            filename
          );
          this.isLoading = false;
          this.selectedItem = -1;
        })
        .catch((error) => {
          if (error.response.status == "403") {
            this.showError(
              `Убедитесь, что пользователь ${this.$store.getters.EMAIL} имеет доступ к файлу`
            );
          } else {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.showError(JSON.parse(res).error);
              });
            } 
          }

          this.isLoading = false;
          this.selectedItem = -1;
        });
    },

    checkRule(rule, pin) {
      this.isLoading = true;

      let body = { id: rule.id, pinCode: pin };
      let linkId = this.$route.params.linkId;
      ApiService.files
        .postGetFile(linkId, body, {
          responseType: "blob",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0];
          this.route(
            {
              allowDownload: response.headers["allowdownload"],
              format: response.headers["fileformat"],
            },
            response.data,
            filename,
            body
          );
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data) {
            error.response.data.text().then((res) => {
              this.showError(JSON.parse(res).error);
            });
          }
          this.isLoading = false;
          this.selectedItem = -1;
        });
    },

    route(parameters, file, filename, checkRuleBody = null) {
      parameters.allowDownload =
        parameters.allowDownload == "True" ? true : false;

      let params = {
        file: file,
        filename: filename,
        allowDownload: parameters.allowDownload,
        linkId: this.$route.params.linkId,
        checkRuleBody: {
          id: checkRuleBody?.id,
          pinCode: checkRuleBody?.pinCode,
        },
      };

      this.saveSessionStorage("paramsStore", params);
      
      if (parameters.format == 1 || parameters.format == 2) {
        // PDF (Office and PDF)
        this.$router.push({ name: "pdfviewer", params: params });
      } else if (parameters.format == 3) {
        // ZIP
        this.$router.push({ name: "zipviewer", params: params });
      } else if (parameters.format == 4) {
        // Image
        this.$router.push({ name: "imageviewer", params: params });
      } else {
        this.isLoading = false;
        this.selectedItem = -1;
        if (parameters.allowDownload)
          this.$router.push({ name: "notviewer", params: params });
        else this.showError("Данный файл не поддерживает предпросмотр");
      }
    },

    saveSessionStorage(keyStorage, parameters) {
      sessionStorage.removeItem(keyStorage);
      sessionStorage.setItem(keyStorage, JSON.stringify(parameters));
    },
  },

  components: {
    StateContainer,
    CustomSnackbar: Snackbar,
    AdListItem,
    AnonListItem,
    PinListItem,
  },
};
</script>
